// src/pages/HomePage.js
import React from "react";
import ProductCard from "../components/ProductCard";
import "./HomePage.css";

function HomePage() {
  const products = [
    { 
        image: "/assets/noski.png", 
        title: "Носок-левый", 
        price: "500",
        type: "Неотразимые носки",
        description: "Инструкция по применению: \
          1. Определите вашу левую ногу \
          2. При наличии предметов, препятствующих надеванию (обувь и пр.) немедленно высвободите конечность \
          3. Определите место расположения носков в вашем шкафу (полка, ящик, отсек) \
          4. Трепетно и не нарушая целостности упаковки извлеките носочно-чулочное изделие \
          5. Наденьте носок",
    },
    { 
        image: "/assets/noski.png", 
        title: "Носок-правый", 
        price: "500",
        type: "Неотразимые носки",
        description: "Инструкция по применению: \
          См. для левого носка \
          Рекомендации по уходу за изделием: во избежание аннулирования гарантийного талона при обнаружении признаков загрязнения КАТЕГОРИЧЕСКИ ЗАПРЕЩАЕТСЯ стирать, гладить, выжимать, отбеливать, сушить до истечения сроков службы изделия. Обращаться в сервисную службу. \
          Срок службы изделия: 63 года, 3 месяца, 2 дня, 7 часов, 13 минут с момента приобретения товара.",
    },
    { 
      image: "/assets/futbolka.png", 
      title: "Футболка", 
      price: "2 500",
      type: "Одежда",
      description: "Футболка с изображения бюста «Взор, устремленный в будущее»",
    },
    { 
      image: "/assets/pidzhak.png", 
      title: "Пиджак Штерна", 
      price: "635 134 897 500",
      type: "Пиджак элитарного клуба",
      description: 'В этом пиджаке завсегдатай литературных клубов Андрей Борисович Штерн посещал кабак «Бродячая собака», где обрел дружбу с Осипом Мандельштамом, Николаем Эрдманом, Даниилом Хармсом, Борисом Пастернаком \
      и Евгением Замятиным. \
      Поэт Сергей Есенин посвятил Штерну следующие строчки: \
      Ты жива еще, моя старушка? \
      Жив и я. Привет тебе, привет!',
    },
    { 
      image: "/assets/sumka.jpg", 
      title: "Сумка-шоппер", 
      price: "1000",
      type: "Элегантный пакет",
      description: "Достославный Андрей Штерн, влекомый жаждою к знаниям и славе, собрал в означенную котомку\
      свои скудные пожитки и пустился в путь-дорогу дальнюю. Впрягшись всеми своими конечностями, костьми и сухожилиями в рыбный обоз, \
      семь лет добирался он до града престольного, белокаменного. Много опасностей подстерегало путника в дороге, но лишь благодаря этому \
      саквояжу, он добрался целым и невредимым, в здравом уме и доброй памяти",
    },
    { 
      image: "/assets/book_1.jpg", 
      title: "Божественное руководство: 9 шагов погружения в успех", 
      price: "3200",
      type: "Книга",
      description: "Пройдите все девять кругов с Андреем Штерном",
    },
    { 
      image: "/assets/god_book_shtern.png", 
      title: "Автобиография. Нетрудно быть богом", 
      price: "4000",
      type: "Манускрипт",
      description: "Один из первых сохранившийся источников об управлении царством земным и царством небесным",
    },
    { 
      image: "/assets/book_2.jpg", 
      title: "От хорошего к великому, я и есть бизнес", 
      price: "350",
      type: "Видеокурс",
      description: "В формате VHS",
    },
    { 
      image: "/assets/krossovki.png", 
      title: "Всесезонные кроссовки", 
      price: "7800",
      type: "",
      description: "Идут в линейке с носками «неотразимыми» и футболкой «Взор, устремленный в будущее». Вся коллекция выполнена итальянским дизайнером Фетуччо Бискоттини, лауреатом Нобелевской премии 1979 г., получившим мировую известность благодаря дизайну оргстекла и скрепок.",
    },
    { 
        image: "/assets/shapka.jpg", 
        title: "Шапка", 
        price: "1500",
        type: "Элитный головной убор. Стандарт",
        description: "Память о том дне, когда к Штерну приезжала семья из Галлии. Андрей Борисович делился с гостями секретами приготовления своего фирменного смузи и случайно уронил одного из наследников семейства в чан с напитком. В благодарность за открывшуюся у ребенка сверхсилу Штерну и был подарен этот милый беретик.",
    },
    { 
        image: "/assets/pen.jfif", 
        title: "Ручка для письма",
        price: 99,
        type: "Зоотовары",
        description: "Шариковая, сменные стержни в наборе (12 шт.)",
    },

    { 
        image: "/assets/ezhednevnik.jpg", 
        title: "Ежедневник", 
        price: "300",
        type: "Календари",
        description: "Рассчитан на год плодотворной изнурительной работы. Количество листов: 2",
    },
    { 
        image: "/assets/butylka.png", 
        title: "Термобутылка", 
        price: "750",
        type: "Спортивное снаряжение",
        description: "",
    },
    { 
        image: "/assets/transformator.png", 
        title: "Зарядная станция", 
        price: "3600",
        type: "Физика. 9 класс",
        description: "Собираетесь в путешествие? Не забудьте взять с собой портативную трансформаторную будку.",
    },

  ];

  return (
    <div>
      <div className="banner">
        <img src="/assets/main_banner.png" alt="Banner" />
      </div>
      <h2>Великие товары</h2>
      <div className="product-grid">
        {products.map((product, index) => (
          <ProductCard key={index} {...product} />
        ))}
      </div>
    </div>
  );
}

export default HomePage;
