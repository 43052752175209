// src/components/ProductCard.js

import React, { useState } from "react";
import "./ProductCard.css";

function ProductCard({ image, title, price, type, description }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  // Устанавливаем лимит видимого описания (например, 100 символов)
  const shortDescription = description.slice(0, 100);

  return (
    <div className="product-card">
      <h3>{title}</h3>
      <img src={image} alt={title} />
      <p className="product-type">{type}</p>
      <p className="product-price">{price}₽</p>
      <p className="product-description">
        {isExpanded ? description : `${shortDescription}...`}
        <button className="toggle-button" onClick={toggleDescription}>
          {isExpanded ? "Скрыть" : "Читать далее"}
        </button>
      </p>
    </div>
  );
}

export default ProductCard;
