// src/components/Header.js
import React from "react";
// import { Link } from "react-router-dom";
import "./Header.css";

function Header() {
  return (
    <header className="header">
      <div className="logo">Shtern Merch Shop</div>
      <nav>
        {/* <Link to="/">Главная</Link> */}
        <div>
          <a href="mailto:shterngod@mail.ru" className="email-link">
              Написать
          </a>

        </div>
      </nav>
    </header>
  );
}

export default Header;
